import { Component, Vue } from 'vue-property-decorator';
import { DefaultAnswer as DefaultAnswerModel, UpdateDefaultAnswerPayload } from '@/models/DefaultAnswer';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Role } from '@/models/Role';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';

@Component<DefaultAnswer>({
  components: {
    QuillEditor,
  },
})

export default class DefaultAnswer extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = false;

  protected isUpdating = false;

  protected isEditingDefaultAnswer = false;

  protected roles: Role[] = [];

  protected defaultAnswer: DefaultAnswerModel | null = null;

  protected stringInputText = '';

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted() {
    this.isLoading = true;
    await this.fetchDefaultAnswer();
    this.emitBreadcrumb();
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Standaardteksten', path: '/instellingen/default-answers' },
          { name: this.defaultAnswer?.title || '' },
        ],
      });
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async fetchDefaultAnswer(): Promise<void> {
    const defaultAnswer = await new DefaultAnswerModel()
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (defaultAnswer) {
      this.defaultAnswer = defaultAnswer;
    }
  }

  protected async update(): Promise<void> {
    if (! this.defaultAnswer) {
      return;
    }

    this.isUpdating = true;

    const payload = this.updatePayload;

    const defaultAnswer = await this.defaultAnswer
      .update(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isUpdating = false;
      });

    if (defaultAnswer) {
      this.defaultAnswer = defaultAnswer;
      this.isUpdating = false;
      this.isEditingDefaultAnswer = false;
    }
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get updatePayload(): UpdateDefaultAnswerPayload {
    return {
      title: this.defaultAnswer?.title || '',
      instruction: this.defaultAnswer?.instruction || '',
      text: this.defaultAnswer?.text || '',
      question: this.defaultAnswer?.question_key || '',
      is_rich_text: this.defaultAnswer?.is_rich_text,
    };
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
