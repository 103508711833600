var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _vm.isLoading
            ? _c("LoaderCard", { attrs: { flat: "", type: "spinner--center" } })
            : _vm._e(),
          !_vm.isLoading && _vm.defaultAnswer
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel profile" },
                      [
                        !_vm.isLoading
                          ? [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "profileContainer",
                                  class: {
                                    editing: _vm.isEditingDefaultAnswer
                                  },
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  !_vm.isEditingDefaultAnswer
                                    ? _c(
                                        "div",
                                        { staticClass: "iconContainer" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "iconContainer__edit"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "edit-icon",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isEditingDefaultAnswer = true
                                                    }
                                                  }
                                                },
                                                [_vm._v("edit")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isEditingDefaultAnswer
                                    ? _c(
                                        "div",
                                        { staticClass: "iconContainer" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "cancel-icon",
                                              on: {
                                                click: function($event) {
                                                  _vm.isEditingDefaultAnswer = false
                                                  _vm.fetchDefaultAnswer()
                                                }
                                              }
                                            },
                                            [_vm._v(" cancel ")]
                                          ),
                                          !_vm.isUpdating
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "save-icon",
                                                  on: { click: _vm.update }
                                                },
                                                [_vm._v("save")]
                                              )
                                            : _vm._e(),
                                          _vm.isUpdating
                                            ? _c("LoaderCard", {
                                                staticClass: "spinner--inline",
                                                attrs: {
                                                  type: "spinner--small"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.defaultAnswer
                                    ? _c(
                                        "v-flex",
                                        { attrs: { "mt-3": "" } },
                                        [
                                          _vm.isEditingDefaultAnswer
                                            ? [
                                                _c("v-text-field", {
                                                  staticClass: "mb-16",
                                                  attrs: { label: "Titel" },
                                                  model: {
                                                    value:
                                                      _vm.defaultAnswer.title,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.defaultAnswer,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "defaultAnswer.title"
                                                  }
                                                }),
                                                _c("v-text-field", {
                                                  staticClass: "mb-16",
                                                  attrs: {
                                                    label: "Question key"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.defaultAnswer
                                                        .question_key,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.defaultAnswer,
                                                        "question_key",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "defaultAnswer.question_key"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tw-flex tw-flex-wrap tw-p-0"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "block tw-text-xl tw-text-gray-700 tw-mb-8"
                                                      },
                                                      [_vm._v("Instructies")]
                                                    ),
                                                    _c("QuillEditor", {
                                                      staticClass: "tw-mb-16",
                                                      model: {
                                                        value:
                                                          _vm.defaultAnswer
                                                            .instruction,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.defaultAnswer,
                                                            "instruction",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "defaultAnswer.instruction"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm.defaultAnswer.is_rich_text
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "block tw-text-xl tw-text-gray-700 tw-mb-8"
                                                        },
                                                        [_vm._v("Tekst")]
                                                      ),
                                                      _c("QuillEditor", {
                                                        model: {
                                                          value:
                                                            _vm.defaultAnswer
                                                              .text,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.defaultAnswer,
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "defaultAnswer.text"
                                                        }
                                                      })
                                                    ]
                                                  : _vm._e(),
                                                !_vm.defaultAnswer.is_rich_text
                                                  ? [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          label: "Tekst"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.defaultAnswer
                                                              .text,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.defaultAnswer,
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "defaultAnswer.text"
                                                        }
                                                      })
                                                    ]
                                                  : _vm._e(),
                                                _c("v-checkbox", {
                                                  staticClass: "tw-mt-8",
                                                  attrs: {
                                                    color: "primary",
                                                    label: "Rich text editor",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.defaultAnswer
                                                        .is_rich_text,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.defaultAnswer,
                                                        "is_rich_text",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "defaultAnswer.is_rich_text"
                                                  }
                                                })
                                              ]
                                            : _vm._e(),
                                          !_vm.isEditingDefaultAnswer
                                            ? [
                                                _c(
                                                  "h1",
                                                  {
                                                    staticClass:
                                                      "tw-font-normal tw-mb-16"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.defaultAnswer.title
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass: "mb-16",
                                                  attrs: {
                                                    label: "Question key",
                                                    disabled: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.defaultAnswer
                                                        .question_key,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.defaultAnswer,
                                                        "question_key",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "defaultAnswer.question_key"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tw-flex tw-flex-wrap tw-p-0"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tw-block tw-w-full tw-text-xl tw-text-gray-700 tw-mb-8"
                                                      },
                                                      [_vm._v("Instructies")]
                                                    ),
                                                    _c("span", {
                                                      staticClass: "tw-w-full",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.defaultAnswer
                                                            .instruction
                                                        )
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tw-flex tw-flex-wrap tw-p-0 tw-mt-16"
                                                  },
                                                  [
                                                    _vm.defaultAnswer
                                                      .is_rich_text
                                                      ? [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tw-block tw-w-full tw-text-xl tw-text-gray-700 tw-mb-8"
                                                            },
                                                            [_vm._v("Tekst")]
                                                          ),
                                                          _c("span", {
                                                            staticClass:
                                                              "tw-w-full",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm
                                                                  .defaultAnswer
                                                                  .text
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      : _vm._e(),
                                                    !_vm.defaultAnswer
                                                      .is_rich_text
                                                      ? [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              label: "Tekst",
                                                              disabled: "",
                                                              "hide-details": ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .defaultAnswer
                                                                  .text,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.defaultAnswer,
                                                                  "text",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "defaultAnswer.text"
                                                            }
                                                          })
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }